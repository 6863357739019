import React, { useState, useEffect } from 'react';
import logo from './logo512.png';

const LoadingScreen = () => {
  const [progress, setProgress] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const loadResources = async () => {
      // Simulate loading resources
      await Promise.all([
        // ...load other resources...
        new Promise(resolve => setTimeout(resolve, 2000)),
      ]);
      setFadeOut(true);
    };

    loadResources();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        const next = prev + 0.7;
        if (next >= 100) {
          clearInterval(interval);
          return 100;
        }
        return next;
      });
    }, 16); // ~60fps

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`fixed inset-0 flex flex-col items-center justify-center bg-macos-dark z-50 transition-opacity duration-500 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
      <div className="relative">
        <img 
          src={logo} 
          alt="Logo" 
          className="w-24 h-24 mb-8 animate-pulse bounceSmooth" 
          style={{ filter: 'drop-shadow(0 0 20px rgba(0, 122, 255, 0.5))' }}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-macos-blue/20 to-transparent animate-shimmer" />
      </div>
      
      <div className="w-64 h-2 bg-gray-700 rounded-full overflow-hidden backdrop-blur-sm">
        <div 
          className="h-full bg-gradient-to-r from-macos-blue via-macos-blue to-macos-blue animate-gradient"
          style={{ 
            width: `${progress}%`,
            transition: 'width 0.2s ease-out',
            boxShadow: '0 0 20px rgba(0, 122, 255, 0.5)'
          }}
        />
      </div>
      
      <p className="mt-4 text-gray-400 text-sm font-medium tracking-wider">
        Loading{'.'.repeat(Math.floor((progress % 300) / 100) + 1)}
      </p>
    </div>
  );
};

export default LoadingScreen;