import React from 'react';
import { SEMESTERS, PROFESSORS, ACADEMIC_YEARS } from '../utils/constants';

function StatisticsForm({ handleFetchStats, filterProfessor, setFilterProfessor, filterSemester, setFilterSemester, filterAcademicYear, setFilterAcademicYear }) {
  const isFilterValid = () => filterSemester && filterProfessor && filterAcademicYear;

  const onSubmit = (e) => {
    e.preventDefault();
    if (isFilterValid()) {
      handleFetchStats({ filterSemester, filterProfessor, filterAcademicYear });
    }
  };

  return (
    <section className="backdrop-blur-lg bg-macos-dark rounded-3xl p-8 shadow-mac border border-gray-700 animate-fadeInUp">
      <h2 className="text-4xl font-bold mb-6 text-macos-blue">Fetch Attendance Statistics</h2>
      <form className="space-y-6" onSubmit={onSubmit}>
        <select
          value={filterSemester}
          onChange={(e) => setFilterSemester(e.target.value)}
          className="w-full p-4 bg-transparent border border-gray-600 rounded-xl focus:ring-2 focus:ring-macos-blue focus:border-transparent transition-all duration-300 outline-none text-white"
        >
          <option value="">Select Semester</option>
          {SEMESTERS.map((sem) => (
            <option key={sem} value={sem}>Semester {sem}</option>
          ))}
        </select>
        <select
          value={filterProfessor}
          onChange={(e) => setFilterProfessor(e.target.value)}
          className="w-full p-4 bg-transparent border border-gray-600 rounded-xl focus:ring-2 focus:ring-macos-blue focus:border-transparent transition-all duration-300 outline-none text-white"
        >
          <option value="">Select Professor</option>
          {PROFESSORS.map((prof) => (
            <option key={prof} value={prof}>{prof}</option>
          ))}
        </select>
        <select
          value={filterAcademicYear}
          onChange={(e) => setFilterAcademicYear(e.target.value)}
          className="w-full p-4 bg-transparent border border-gray-600 rounded-xl focus:ring-2 focus:ring-macos-blue focus:border-transparent transition-all duration-300 outline-none text-white"
        >
          {ACADEMIC_YEARS.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <button
          type="submit"
          disabled={!isFilterValid()}
          className={`w-full bg-gradient-to-r from-green-500 to-blue-500 text-white p-4 rounded-xl hover:from-green-600 hover:to-blue-600 transition duration-300 ease-in-out text-lg font-semibold ${!isFilterValid() ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Fetch Statistics
        </button>
      </form>
    </section>
  );
}

export default StatisticsForm;