import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

function LoginForm({ handleLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    if (recaptchaToken) {
      handleLogin(email, password);
    } else {
      alert("Please verify that you are not a robot.");
    }
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <section className="backdrop-blur-lg bg-macos-dark rounded-3xl p-8 shadow-mac border border-gray-700 transition-all duration-300 animate-fadeInUp">
      <h2 className="text-4xl font-bold mb-6 text-macos-blue">
        Login
      </h2>
      <form onSubmit={onSubmit} className="space-y-6">
        <div className="relative group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-4 bg-transparent border border-gray-600 rounded-xl focus:ring-2 focus:ring-macos-blue focus:border-transparent transition-all duration-300 outline-none text-white placeholder-gray-400"
            placeholder="Email"
            required
          />
        </div>
        <div className="relative group">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-4 bg-transparent border border-gray-600 rounded-xl focus:ring-2 focus:ring-macos-blue focus:border-transparent transition-all duration-300 outline-none text-white placeholder-gray-400"
            placeholder="Password"
            required
          />
        </div>
        <ReCAPTCHA
          sitekey="6LdgbTAqAAAAANrHylxssZEKe3cUoZMwOlYQBc_M"
          onChange={onRecaptchaChange}
        />
        <button
          type="submit"
          className={`w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white p-4 rounded-lg hover:from-blue-600 hover:to-purple-600 transition duration-300 ease-in-out text-lg font-semibold ${!recaptchaToken ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={!recaptchaToken}
        >
          Login
        </button>
      </form>
    </section>
  );
}

export default LoginForm;
