import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import LoginForm from "./components/LoginForm";
import AttendanceForm from "./components/AttendanceForm";
import StatisticsForm from "./components/StatisticsForm";
import StatisticsTable from "./components/StatisticsTable";
import DownloadPDFButton from "./components/DownloadPDFButton";
import LoadingScreen from "./components/LoadingScreen";

import useAuth from "./hooks/useAuth";
import useAttendance from "./hooks/useAttendance";
import useStatistics from "./hooks/useStatistics";
import { ACADEMIC_YEARS } from './utils/constants';

function App() {
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initializeApp = async () => {
      // Start loading resources
      const loadResources = async () => {
        await new Promise(resolve => setTimeout(resolve, 2000));
        // ...load other resources...
      };

      loadResources().then(() => {
        setInitialized(true);
        setLoading(false);
      });
    };

    initializeApp();
  }, []);

  const { user, handleLogin, handleLogout } = useAuth();
  const { attendance, handleAddAttendance } = useAttendance();
  const { 
    filteredStatistics, 
    showStatistics, 
    handleFetchStats, 
    dataFetched 
  } = useStatistics();

  const [professor, setProfessor] = useState("");
  const [semester, setSemester] = useState("");
  const [academicYear, setAcademicYear] = useState(ACADEMIC_YEARS[0]);

  const [filterProfessor, setFilterProfessor] = useState("");
  const [filterSemester, setFilterSemester] = useState("");
  const [filterAcademicYear, setFilterAcademicYear] = useState(ACADEMIC_YEARS[0]);

  const downloadProfessor = filterProfessor || professor;
  const downloadSemester = filterSemester || semester;
  const downloadAcademicYear = filterAcademicYear || academicYear;

  console.log("Download Professor:", downloadProfessor);
  console.log("Download Semester:", downloadSemester);
  console.log("Download Academic Year:", downloadAcademicYear);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-blue-900">
      {!initialized ? (
        <LoadingScreen />
      ) : (
        <div className={`transition-all duration-700 ${loading ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="container mx-auto p-4 sm:p-8">
            <div className="backdrop-blur-xl bg-white/10 rounded-3xl shadow-mac p-8 border border-white/20">
              <header className="text-center animate-fadeInUp">
                <h1 className="text-5xl sm:text-7xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-4">
                  CUSAC
                </h1>
                <p className="text-3xl sm:text-4xl font-semibold text-white">
                  Attendance Portal
                </p>
              </header>
              
              <div className="space-y-8 transition-all duration-300">
                {!user ? (
                  <div className="animate-fadeIn">
                    <LoginForm handleLogin={handleLogin} />
                  </div>
                ) : (
                  <div className="space-y-8 animate-fadeIn">
                    <button
                      onClick={handleLogout}
                      className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-300 ease-in-out"
                    >
                      Logout
                    </button>

                    <AttendanceForm
                      handleAddAttendance={handleAddAttendance}
                      professor={professor}
                      setProfessor={setProfessor}
                      semester={semester}
                      setSemester={setSemester}
                      academicYear={academicYear}
                      setAcademicYear={setAcademicYear}
                    />

                    <StatisticsForm
                      handleFetchStats={handleFetchStats}
                      filterProfessor={filterProfessor}
                      setFilterProfessor={setFilterProfessor}
                      filterSemester={filterSemester}
                      setFilterSemester={setFilterSemester}
                      filterAcademicYear={filterAcademicYear}
                      setFilterAcademicYear={setFilterAcademicYear}
                    />

                    {showStatistics && (
                      <StatisticsTable filteredStatistics={filteredStatistics} />
                    )}

                    <DownloadPDFButton 
                      dataFetched={dataFetched} 
                      filteredStatistics={filteredStatistics} 
                      attendance={attendance}
                      professor={downloadProfessor}
                      semester={downloadSemester}
                      academicYear={downloadAcademicYear}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
