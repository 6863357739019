import React, { useState } from 'react';
import { SEMESTERS, PROFESSORS, ACADEMIC_YEARS } from '../utils/constants';

function AttendanceForm({ handleAddAttendance, professor, setProfessor, semester, setSemester, academicYear, setAcademicYear }) {
  const [rollNumber, setRollNumber] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  const isFormValid = () => rollNumber && date && semester && professor && academicYear;

  const onSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      handleAddAttendance({ rollNumber, date, semester, professor, academicYear });
      setRollNumber("");
    }
  };
  return (
    <section className="backdrop-blur-lg bg-macos-dark rounded-3xl p-8 shadow-mac border border-gray-700 animate-fadeInUp">
      <h2 className="text-4xl font-bold mb-6 text-macos-blue">Add Attendance</h2>
      <form className="space-y-6" onSubmit={onSubmit}>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="w-full p-4 bg-transparent border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-300 outline-none text-white placeholder-gray-400"
        />
        <select
          value={semester}
          onChange={(e) => setSemester(e.target.value)}
          className="w-full p-4 bg-transparent border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-300 outline-none text-white placeholder-gray-400"
        >
          <option value="">Select Semester</option>
          {SEMESTERS.map((sem) => (
            <option key={sem} value={sem}>Semester {sem}</option>
          ))}
        </select>
        <select
          value={professor}
          onChange={(e) => setProfessor(e.target.value)}
          className="w-full p-4 bg-transparent border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-300 outline-none text-white placeholder-gray-400"
        >
          <option value="">Select Professor</option>
          {PROFESSORS.map((prof) => (
            <option key={prof} value={prof}>{prof}</option>
          ))}
        </select>
        <select
          value={academicYear}
          onChange={(e) => setAcademicYear(e.target.value)}
          className="w-full p-4 bg-transparent border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-300 outline-none text-white placeholder-gray-400"
        >
          {ACADEMIC_YEARS.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <input
          type="number"
          value={rollNumber}
          onChange={(e) => setRollNumber(e.target.value)}
          placeholder="Enter roll number"
          className="w-full p-4 bg-transparent border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-300 outline-none text-white placeholder-gray-400"
        />
        <button
          type="submit"
          disabled={!isFormValid()}
          className={`w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white p-4 rounded-xl hover:from-blue-600 hover:to-purple-600 transition duration-300 ease-in-out text-lg font-semibold ${!isFormValid() ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Submit
        </button>
      </form>
    </section>
  );
}

export default AttendanceForm;