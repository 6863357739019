import React from 'react';

function StatisticsTable({ filteredStatistics }) {
  return (
    <section className="backdrop-blur-lg bg-macos-dark rounded-3xl p-8 shadow-mac animate-fadeInUp">
      <h2 className="text-4xl font-bold mb-6 text-macos-blue">Statistics</h2>
      <table className="w-full text-sm text-left text-white">
        <thead className="text-xs uppercase bg-gray-700 text-white">
          <tr>
            <th scope="col" className="px-6 py-4 rounded-tl-lg">Roll Number</th>
            <th scope="col" className="px-6 py-4 rounded-tr-lg">Attended Lectures</th>
          </tr>
        </thead>
        <tbody>
          {filteredStatistics.map((stat, index) => (
            <tr key={stat.rollNumber} className={`${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'} hover:bg-gray-600 transition duration-200 ease-in-out`}>
              <td className="px-6 py-4 font-medium text-macos-gray whitespace-nowrap">{stat.rollNumber}</td>
              <td className="px-6 py-4">{stat.attendedLectures}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default StatisticsTable;