import React from 'react';
import { generatePDF } from '../utils/pdfGenerator';

function DownloadPDFButton({ dataFetched, filteredStatistics, attendance, professor, semester, academicYear }) {
  const handleDownloadPdf = () => {
    console.log("Generating PDF with:");
    console.log("Professor:", professor);
    console.log("Semester:", semester);
    console.log("Academic Year:", academicYear);

    generatePDF(filteredStatistics, attendance, professor, semester, academicYear);
  };

  return (
    <button
      onClick={handleDownloadPdf}
      disabled={!dataFetched}
      className={`w-full sm:w-auto bg-gradient-to-r from-red-500 to-red-700 text-white px-8 py-4 rounded-xl hover:from-red-600 hover:to-red-800 transition duration-300 ease-in-out flex items-center justify-center text-lg font-semibold ${!dataFetched ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      Download PDF Report
    </button>
  );
}

export default DownloadPDFButton;
